<script setup>
useSeoMeta({
  title: "VimiCrypto - The best crypto portfolio tracker",
  ogTitle: "VimiCrypto - The best crypto portfolio tracker",
  description:
    "Track your crypto portfolio with ease. Get insights and analytics on your investments.",
  ogDescription:
    "Track your crypto portfolio with ease. Get insights and analytics on your investments.",
  ogImage: "https://vimicrypto.com/images/logo.svg",
  twitterImage: "https://vimicrypto.com/images/logo.svg",
  twitterCard: "logo",
  ogUrl: "https://vimicrypto.com",
  twitterTitle: "VimiCrypto - The best crypto portfolio tracker",
  twitterDescription:
    "Track your crypto portfolio with ease. Get insights and analytics on your investments.",
});

useHead({
  htmlAttrs: {
    lang: "en",
  },
  link: [
    {
      rel: "icon",
      type: "image/x-icon",
      href: "/favicon.ico",
    },
  ],
  script: [
    {
      children: `(function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "nk9g0cih7q");`,
    },
  ],
});

const router = useRouter();
const user = useCurrentUser();

onMounted(() => {
  watch(user, (user, prevUser) => {
    if (prevUser && !user) {
      // user logged out
      router.push("/");
    }
  });
});

const { isLoading } = useLoadingIndicator();
</script>

<template>
  <NuxtLoadingIndicator>
    <UModal
      v-model="isLoading"
      :overlay="false"
      :ui="{ background: 'bg-transparent dark:bg-transparent' }">
      <LoadingStatus :pending="true" />
    </UModal>
  </NuxtLoadingIndicator>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <UNotifications />
</template>
