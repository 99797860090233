import { default as forgot487FlM3HxjMeta } from "/vercel/path0/pages/forgot.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as indexnpJzOswtosMeta } from "/vercel/path0/pages/system/index.vue?macro=true";
import { default as callbackrXsvrkGYZdMeta } from "/vercel/path0/pages/system/integration/coinbase/callback.vue?macro=true";
import { default as indexwBM7QkV6PPMeta } from "/vercel/path0/pages/system/integration/index.vue?macro=true";
import { default as coin_45summarygdDj8vKty3Meta } from "/vercel/path0/pages/system/reports/coin-summary.vue?macro=true";
import { default as indexxKgwIA04SqMeta } from "/vercel/path0/pages/system/reports/index.vue?macro=true";
import { default as pnllniypqwg7YMeta } from "/vercel/path0/pages/system/reports/pnl.vue?macro=true";
import { default as taxgWluCwJWoMMeta } from "/vercel/path0/pages/system/reports/tax.vue?macro=true";
import { default as _91_91settingsTab_93_93VT6ZYD9WdKMeta } from "/vercel/path0/pages/system/settings/[[settingsTab]].vue?macro=true";
import { default as closed4i9wrzN5A4Meta } from "/vercel/path0/pages/system/trade/closed.vue?macro=true";
import { default as index8jqSeloquDMeta } from "/vercel/path0/pages/system/trade/index.vue?macro=true";
import { default as _91_91accountTab_93_930xBu9yC9h9Meta } from "/vercel/path0/pages/system/user/account/[[accountTab]].vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as twofaverifyjxACgoiJHJMeta } from "/vercel/path0/pages/twofaverify.vue?macro=true";
import { default as manageIunCdIKJ4kMeta } from "/vercel/path0/pages/user/manage.vue?macro=true";
import { default as verifyYegocVK4w5Meta } from "/vercel/path0/pages/verify.vue?macro=true";
export default [
  {
    name: "forgot",
    path: "/forgot",
    meta: forgot487FlM3HxjMeta || {},
    component: () => import("/vercel/path0/pages/forgot.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "system",
    path: "/system",
    meta: indexnpJzOswtosMeta || {},
    component: () => import("/vercel/path0/pages/system/index.vue")
  },
  {
    name: "system-integration-coinbase-callback",
    path: "/system/integration/coinbase/callback",
    component: () => import("/vercel/path0/pages/system/integration/coinbase/callback.vue")
  },
  {
    name: "system-integration",
    path: "/system/integration",
    component: () => import("/vercel/path0/pages/system/integration/index.vue")
  },
  {
    name: "system-reports-coin-summary",
    path: "/system/reports/coin-summary",
    component: () => import("/vercel/path0/pages/system/reports/coin-summary.vue")
  },
  {
    name: "system-reports",
    path: "/system/reports",
    component: () => import("/vercel/path0/pages/system/reports/index.vue")
  },
  {
    name: "system-reports-pnl",
    path: "/system/reports/pnl",
    component: () => import("/vercel/path0/pages/system/reports/pnl.vue")
  },
  {
    name: "system-reports-tax",
    path: "/system/reports/tax",
    component: () => import("/vercel/path0/pages/system/reports/tax.vue")
  },
  {
    name: "system-settings-settingsTab",
    path: "/system/settings/:settingsTab?",
    component: () => import("/vercel/path0/pages/system/settings/[[settingsTab]].vue")
  },
  {
    name: "system-trade-closed",
    path: "/system/trade/closed",
    component: () => import("/vercel/path0/pages/system/trade/closed.vue")
  },
  {
    name: "system-trade",
    path: "/system/trade",
    component: () => import("/vercel/path0/pages/system/trade/index.vue")
  },
  {
    name: "system-user-account-accountTab",
    path: "/system/user/account/:accountTab?",
    component: () => import("/vercel/path0/pages/system/user/account/[[accountTab]].vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsq5qlZHSSonMeta || {},
    component: () => import("/vercel/path0/pages/terms.vue")
  },
  {
    name: "twofaverify",
    path: "/twofaverify",
    meta: twofaverifyjxACgoiJHJMeta || {},
    component: () => import("/vercel/path0/pages/twofaverify.vue")
  },
  {
    name: "user-manage",
    path: "/user/manage",
    meta: manageIunCdIKJ4kMeta || {},
    component: () => import("/vercel/path0/pages/user/manage.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifyYegocVK4w5Meta || {},
    component: () => import("/vercel/path0/pages/verify.vue")
  }
]